@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.addToCartModal {
  & .container {
    color: $color-default;
    z-index: 1000;
  }

  & .mantine-Modal-inner {
    z-index: 1001;
  }

  & .modalTitle {
    font-weight: 400;
    font-size: $text-xl;
    line-height: 4.2rem;
    white-space: pre-wrap;

    @include respond(tab-port) {
      font-size: $text-xl;
      line-height: 3.6rem;
    }
  }

  & .modalText {
    padding: 0;
    font-weight: 400;
    line-height: 2.4rem;

    @include respond(tab-port) {
      font-size: $text-lg;
      line-height: 2.6rem;
    }
  }

  & .modalWrapper {
    margin: 0;
    color: $color-default;
    padding: 0 4rem 0 4rem;

    @include respond(tab-port) {
      padding: 0 4rem 0 4rem;
    }
  }

  & .mantine-Modal-header {
    height: auto !important;
    padding: 3.2rem 0;
    display: flex;
    gap: 3rem;
    size: 2rem;

    @include respond(tab-port) {
      padding-top: 3.6rem;
      padding-bottom: 2.8rem;
      gap: 2rem;
    }

    @include respond(mobile) {
      gap: 0;
    }
  }

  & .modalBody {
    padding: 0;
  }

  & .upperCloseButton {
    position: absolute;
    top: 10px;
    right: -30px;
    display: flex;
    align-items: flex-start;
    height: 3rem;
    width: 3rem;

    & svg {
      color: $color-default;
    }

    @include respond(big-desktop) {
      height: 4rem;
      width: 4rem;
    }

    @include respond(tab-port) {
      height: 4rem;
      width: 4rem;
      right: -25px;
    }
  }

  & .contentWrapper {
    display: flex;
    gap: 3.2rem;

    @include respond(tab-port) {
      flex-direction: column;
      align-items: flex-start;
      gap: 3rem;
    }
  }

  & .metaInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  & .modalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 3.2rem;

    & form {
      width: 100%;

      & button {
        margin-top: 5.2rem;
      }
    }

    & .approved {
      border-color: $color-success;

      & legend {
        color: $color-success;
      }
    }

    & .failed {
      border-color: $color-error;

      & legend {
        color: $color-error;
      }
    }

    @include respond(tab-port) {
      flex-direction: column;
      align-items: center;
    }
  }

  & .lowerCloseButton {
    margin-left: 1.6rem;
    color: $color-default;
    padding: 0;
    background-color: inherit;
    border: none;
    font-weight: 400;
    line-height: 1.3rem;
    text-transform: uppercase;
    transition: all 0.3s ease;

    &:hover {
      color: $color-light-grey;
    }

    &:focus {
      color: $color-light-grey;
    }

    @include respond(tab-port) {
      margin-bottom: 1.6rem;
    }
  }

  & .cartButton {
    background-color: $color-default;
    border: 1.5px solid $color-default;
    color: $color-white;
    transition: all 0.3s ease;
    font-weight: 400;
    line-height: 1.3rem;
    text-transform: uppercase;
    height: auto !important;
    margin-right: 1.6rem;

    &:hover {
      border: 1.5px solid $color-light-grey;
      background-color: $color-light-grey;
    }

    &:focus {
      border: 1.5px solid $color-light-grey;
      background-color: $color-light-grey;
    }

    & span {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }

    @include respond(tab-port) {
      width: 100%;
    }
  }
}
