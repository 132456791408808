@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.mantine-Checkbox-body {
  display: flex;
  gap: 1.25rem;
  align-items: center;
}

.mantine-Checkbox-inner {
  & input {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    accent-color: #0f0f0f;
    border: 1px solid $color-default !important;

    &:checked {
      background-color: $color-white !important;
    }

    @include respond(mobile) {
      width: 2rem;
      height: 2rem;
    }
  }

  & svg {
    display: none;
    color: $color-default !important;
    -webkit-transform: translateY(10px) scale(0.5);
    -moz-transform: translateY(10px) scale(0.5);
    -ms-transform: translateY(10px) scale(0.5);
    transition-delay: 0.5s;
    transition: visibility 0s, opacity 0.5s;
  }

  & input:checked + svg {
    display: block;
  }
}

.mantine-Anchor-root {
  font-weight: 600;
  color: $color-light-grey;
}

.requiredCheckbox label:after {
  display: inline;
  content: ' *';
  color: $color-error;
}

.checkoutCheckboxWrapper {
  margin-top: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  & .mantine-Checkbox-label {
    font-weight: 500;
    font-size: $text-sm;
    line-height: 1.6rem;
    color: $color-default;

    @include respond(tablet) {
      font-size: $text-sm;
      line-height: 1.9rem;
    }

    @include respond(mobile) {
      line-height: 2.2rem;
    }
  }

  @include respond(tab-port) {
    padding-block: 3rem;
  }
}

.newsletterCheckboxWrapper {
  margin-top: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  & .mantine-Checkbox-label {
    font-weight: 500;
    font-size: $text-sm;
    line-height: 1.6rem;
    color: $color-default;
    justify-content: center;

    @include respond(tablet) {
      font-size: $text-sm;
      line-height: 1.9rem;
    }

    @include respond(tab-land) {
      padding-left: 1.5rem;
      font-size: $text-sm;
    }

    @include respond(mobile) {
      font-size: $text-sm;
      line-height: 2.2rem;
    }
  }

  @include respond(tab-port) {
    padding-block: 3rem;
  }
}
