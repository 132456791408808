.infoModal {
  & .container {
    color: $color-default !important;
    z-index: 1000;
  }

  & .mantine-Modal-inner {
    z-index: 1001;
  }

  & .modalWrapper {
    color: $color-default;
    padding: 0 3.2rem 3.2rem 3.2rem !important;
    margin: 0;

    @include respond(tab-port) {
      padding: 0 3rem 2.5rem 3rem !important;
    }
  }

  & .mantine-Modal-header {
    height: auto !important;
    padding-block: 3.3rem !important;
    padding-bottom: 2rem !important;
  }

  & .modalTitle {
    font-weight: 400;
    line-height: 2.7rem;

    @include respond(tab-port) {
      font-size: $text-xl;
      line-height: 3.3rem;
    }
  }

  & .modalBody {
    gap: 1rem;
    padding-inline: 1.6rem !important;
    font-size: $text-sm;
    font-weight: 400;
    line-height: 2.7rem;

    & p {
      margin-top: 0.8rem;

      &:last-child {
        margin-top: 1.6rem;
        text-align: right;
        padding-right: 10%;
      }

      @include respond(tab-port) {
        &:last-child {
          font-size: $text-lg;
        }
      }
    }
  }

  & .upperCloseButton {
    position: absolute !important;
    right: 0;
    top: 25%;
    padding: 1.5px;
    color: $color-default;
    height: 25px;
    width: 25px;
  }
}
