@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.mantine-Group-root {
  display: flex;
  justify-content: flex-start !important;
}

.mantine-ColorSwatch-root {
  width: 2.9rem !important;
  height: 2.9rem !important;
  background-color: white !important;
  border: 1px solid $color-border;
}

.mantine-ColorSwatch-overlay {
  margin: 0.25rem;
}
